@font-face {
  font-family: 'interriskUkraine';
  src:  url('./fonts/interriskUkraine.eot?1n83s3');
  src:  url('./fonts/interriskUkraine.eot?1n83s3#iefix') format('embedded-opentype'),
    url('./fonts/interriskUkraine.ttf?1n83s3') format('truetype'),
    url('./fonts/interriskUkraine.woff?1n83s3') format('woff'),
    url('./fonts/interriskUkraine.svg?1n83s3#interriskUkraine') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"]::before, [class*=" _icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'interriskUkraine' !important;
  // speak: never; ???
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-suitcase-bag:before {
  content: "\e912";
}
._icon-balance:before {
  content: "\e911";
}
._icon-attach:before {
  content: "\e900"; // headphones
}
._icon-call-support:before {
  content: "\e901";
}
._icon-chat:before {
  content: "\e902";
}
._icon-chow-chow:before {
  content: "\e903";
}
._icon-coins-currency:before {
  content: "\e904";
}
._icon-competition-cup:before {
  content: "\e905";
}
._icon-content:before {
  content: "\e906";
}
._icon-global:before {
  content: "\e907";
}
._icon-handshake-deal:before {
  content: "\e908";
}
._icon-information-info:before {
  content: "\e909";
}
._icon-info:before {
  content: "\e90a";
}
._icon-open-book:before {
  content: "\e90b";
}
._icon-search:before {
  content: "\e90c";
}
._icon-support-technology:before {
  content: "\e90d";
}
._icon-targeting-target:before {
  content: "\e90e";
}
._icon-technical-support:before {
  content: "\e90f";
}
._icon-writing-note:before {
  content: "\e910";
}
