@import '../../variables.scss';

.mobileNav {
  background-color: $primary-blue;
  padding: 7px 0;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
}

.burger_menu {
  height: 30px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.burger_bar {
  height: 4px;
  width: 100%;
  background-color: #fff;
  border-radius: 0.5em;
}

/* ------------- dropdown nav ------------------ */

.list {
  overflow: hidden;
  position: absolute;
  background-color: $primary-blue;
  width: 100%;
  padding-left: 0;
}

.item {
  list-style: none;
}

.link {
  text-decoration: none;
  color: $white-color;
  text-transform: uppercase;
  display: block;
  padding: 15px 30px;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    color: $dark-blue;
    background-color: $primary-blue-dark;

    transition: color ease-out 0.3s;
  }
}

/* ------------- sliding menu ------------------ */
.hidden {
  border-top: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: ease 0.3s;
}

.visible {
  border-top: 1px solid $primary-blue-dark;

  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: ease 0.3s;
}

/* ----- animations--------- */
/* clicked */
.burger_bar.clicked:nth-child(1){
  transform: rotate(45deg) translate(9px, 9px);
  transition: ease-out 0.5s;
}

.burger_bar.clicked:nth-child(2){
  transform: scale(0.1);
  transition: ease-out 0.5s;
}

.burger_bar.clicked:nth-child(3){
  // transform: rotate(135deg) translate(-0.5em, 1em);
  transform: rotate(-45deg) translate(9px, -9px);
  transition: ease-out 0.5s;
}

/* unclicked */
.burger_bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}
