@import '../../variables.scss';
@import '../../mixins.scss';

.button {
  background-color: $white-color;
  margin-left: 4px;
  color: #000;

  border: none;
  border-radius: 13px;

  font-weight: bold;
  text-align: center;

  height: 28px;
  width: 38px;
  background-size: contain;

  cursor: pointer;

  @include onDesktop {
    background-color: $white-color;
    text-transform: uppercase;
    width: 27px;
    font-weight: 500;
    font-size: 14px;
  }
}

.button:first-of-type {
  margin-left: 0px;
}

.active {
  color: $white-color;
  background-color: $primary-blue;

  @include onDesktop {
    color: $primary-blue;
    background-color: $white-color;
  }
}