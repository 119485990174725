@import '../../variables.scss';
@import '../../mixins.scss';

.logo {
  height: 50px;

  @include onTablet {
    height: 70px;
  }
}

.link {
  height: 100%;
}
