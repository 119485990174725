@import '../../mixins.scss';
@import '../../variables.scss';

.years_container {
  margin: 0 20px;

  @include onDesktop {
    max-width: 1250px;
    margin: 0 auto;
  }
}

.description {
  color: $dark-grey;
  font-size: 10px;

  @include onTablet {
    font-size: 12px;
  }

  @include onDesktop {
    font-size: 14px;
  }
}

.year_container {
  width: 33.3333%;
  padding: 20px 15px;

  @include onTablet {
    padding: 20px 30px;
  }

  @include onDesktop {
    padding: 20px 70px;
  }
}

.img {
  height: 35px;
  max-width: 100%;
  margin-bottom: 10px;

  @include onTablet {
    height: 55px;
  }

  @include onDesktop {
    height: 85px;
  }
}

.h3 {
  color: $primary-blue;
  font-size: 20px;

  @include onTablet {
    font-size: 24px;
  }

  @include onDesktop {
    font-size: 50px;
  }
}

.year {
  color: $primary-blue;

  @include onTablet {
    font-size: 20px;
  }

  @include onDesktop {
    font-size: 30px;
  }
}

.lines_container {
  display: flex;
  margin: 0 15px;

  @include onTablet {
    margin: 0 20px;
  }

  @include onDesktop {
    margin: 0 30px;
  }
}

.w7 {
  width: 14.28571%;
}

.w17 {
  width: 5.88235%;
}

.w6 {
  width: 16.666666%;
}

.line_big {
  border-left: 5px solid $primary-blue;
  display: inline-block;
  height: 55px;
}

.line_small {
  border-left: 3px solid $primary-blue;
  display: inline-block;
  height: 30px;
}
