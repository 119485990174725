@import '../../variables.scss';

.link {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 10px 30px;
}

.white {
  color: $white-color;
  border: 2px solid $white-color;

  &:hover {
    color: $dark-blue;
    background-color: rgba(255,255,255,.55);

    transition: all ease-out 0.3s;
  }
}

.blue {
  color: $primary-blue;
  border: 2px solid $primary-blue;

  &:hover {
    color: $white-color;
    background-color: $primary-blue-light;

    transition: all ease-out 0.3s;
  }
}
