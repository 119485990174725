@import '../../variables.scss';
@import '../../mixins.scss';

.container {

  @include onTablet {
    padding-bottom: 100px;
  }
}

.paragraph {
  color: $white-color;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
  font-family: $font-regular;

  @include onDesktop {
    font-size: 18px;
    line-height: 28px;
  }
}
