@import '../../variables.scss';
@import '../../mixins.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
}

.qustion_title {
  color: $dark-grey;
  font-size: 18px;
  border-bottom: 2px solid $dark-grey;
  cursor: pointer;
  padding-bottom: 10px;

  &:hover {
    color: $primary-blue;
    border-bottom: 2px solid $primary-blue;
    transition: all .35s;
  }
}

.qustion_container {
  width: 100%;

  @include onTablet {
    width: calc(33.333% - 30px);
  }
}
