@import '../../variables.scss';
@import '../../mixins.scss';

.topNav {
  background-color: $white-color;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 20px;
  @include onTablet {
    height: 93px;
  }

  @include onDesktop {
    margin: 0 auto;
    max-width: 1250px;
  }
}

.logo {
  margin: 0 auto 0 0;
}

.numbers {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px 15px 15px 0;

  @include onTablet {
    display: inline-block;
    margin: 0 30px 0 0;
    padding: 0;
  }

  @include onDesktop {
    margin: 0 40px 0 0;
  }

  & > a:first-child {
    @include onTablet {
      margin: 0 30px 0 0;
    }

    @include onDesktop {
      margin: 0 40px 0 0;
    }
  }
}
