@import '../../mixins.scss';
@import '../../variables.scss';

.text {
  padding: 40px 0 30px;
  font-family: $font-regular;
  font-weight: 700;
  line-height: 38px;

  @include onDesktop {
    font-size: 50px;
    line-height: 80px;
  }

}

.blue {
  color: $primary-blue;
}

.white {
  color: $white-color;
}

// .center {
//   text-align: center;
// }

// .end {
//   text-align: end;
// }

// .textTransform {
//   text-transform: uppercase;
// }
