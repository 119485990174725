@import '../../mixins.scss';
@import '../../variables.scss';

.list {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.link {
  color: $white-color;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 150px;

  @include onDesktop {
    width: 200px;
    font-size: 13px;
  }

  &:hover::before {
    color: #2898f1;
    background-color: #fff;
    transition: all 0.3s;
  }

  &:hover {
    color: $white-color;
  }

  &::before {
    font-size: 40px;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 30px;

    @include onTablet {
      font-size: 55px;
      width: 109px;
      height: 109px;
    }
  }
}
