@mixin onFablet {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin onTablet {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin iconSize($size) {
  width: $size;
  height: $size;
}