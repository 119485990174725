@import '../../variables.scss';
@import '../../mixins.scss';

.container {
  background-color: $white-color;
  padding: 20px 20px;
  border-radius: 12px;
  width: 100%;
  margin: 20px;
  max-width: 550px;

  @include onTablet {
    width: 400px;
  }
}