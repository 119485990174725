@import '../../variables.scss';
@import '../../mixins.scss';

.link {
  outline: none;
  text-decoration: none;
  color: $dark-grey;
}

.phone {
  font-weight: 500;
  
  &::before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 26px;
    background-image: url(../../assets/icons.png);
    background-size: inherit;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    margin-right: 5px;
  }

  &:hover {
    color: $dark-grey;
  }

  @include onTablet {
    font-size: 20px;
  }

  @include onDesktop {
    font-size: 24px;
  }
}

.email {
  font-weight: 400;

  &::before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 26px;
    background-image: url(../../assets/icons.png);
    background-size: inherit;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: -54px 0;
    vertical-align: middle;
    margin-right: 5px;
  }

  &:hover {
    color: $dark-grey;
  }

  @include onTablet {
    margin-right: 40px;
    font-size: 14px;
  }

  @include onDesktop {
    font-size: 15px;
  }
}
