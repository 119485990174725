@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap');

$primary-blue: rgb(40, 152, 241);
$primary-blue-light: rgba($primary-blue,.7);
$primary-blue-dark: #2884db;
$dark-blue: #012a58;
$dark-grey: rgb(48, 60, 66);
$white-color: white;
$font-family: 'Fira Sans', Arial, sans-serif;
$font-regular: 'FiraRegular', Arial, sans-serif;
$font-medium: 'FiraMedium', Arial, sans-serif;
