@import '../../variables.scss';
@import '../../mixins.scss';

.modal {
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
