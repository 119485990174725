@import '../../variables.scss';
@import '../../mixins.scss';

.image {
  display: inline-block;
}

.topContainer {
  display: flex;
  align-items: center;

  & h6 {
    padding: 40px 0 30px;
    font-size: 18px;
    font-family: $font-regular;
    font-weight: 700;
    line-height: 28px;

    @include onDesktop {
      font-size: 24px;
      line-height: 38px;
    }
  }
}

.white {
  color: $white-color;
}

.blue {
  color: $primary-blue;
}

.image::before {
  font-size: 40px;
  background-color: $primary-blue;
  border-radius: 50%;
  margin-right: 30px;
  padding: 10px;
  color: $white-color;
  display: inline-block;
}

.contentContainer {
  display: flex;
  padding-bottom: 40px;
  color: $dark-grey;
  flex-direction: column;

  @include onTablet {
    flex-direction: row;
  }

  & > div {
    width: 100%;

    @include onTablet {
      width: 50%;
    }
    
    &:first-of-type {
      margin-bottom: 40px;

      @include onTablet {
        margin-bottom: 0px;
      }
    }

    &:nth-last-child(1) {

      @include onTablet {
        margin-left: 30px;
      }
    }

    & > p {
      font-family: $font-regular;
      font-size: 14px;


      &:last-of-type {
        padding-bottom: 10px;
      }
    }
  }
}
