@import '../../variables.scss';
@import '../../mixins.scss';

.slider {
  background-color: $white-color;
  width: calc(100vw - 30px);
  max-height: calc(100vh - 100px);
  margin: 20px;
  border-radius: 12px;

  @include onTablet {
    width: 900px;
  }

  @include onDesktop {
    width: 1000px;
  }
}

.slide {
  padding: 20px 60px;
  max-height: 100vh;
  overflow-y: auto;
}

.title {
  
}

.text {
  
}