@import '../../mixins.scss';
@import '../../variables.scss';

.paragraph {
  color: $dark-grey;
  font-size: 13px;
  font-weight: 400;
  text-align: center;

  @include onTablet {
    font-size: 16px;
  }
}

.h4 {
  color: $primary-blue; 
  text-align: center;
  margin-bottom: 20px;

  @include onTablet {
    font-size: 20px;
    margin-bottom: 40px;
  }

  @include onDesktop {
    font-size: 36px;
    margin-bottom: 110px;
  }
}

.list_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.avards_container {
  min-width: 100px;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include onTablet {
    min-width: 200px;
    max-width: 250px;
  }
}

.big_title {
  color: $primary-blue;
  text-align: center;
  font-size: 48px;
  font-weight: 500;

  @include onTablet {
    font-size: 72px;
  }

  @include onDesktop {
    font-size: 100px;
  }
}

.small_title {
  color: $primary-blue;
  text-align: center;
  font-size: 13px;

  @include onTablet {
    font-size: 18px;
  }

  @include onDesktop {
    font-size: 30px;
  }
}

.img {
  height: 58px;
  margin: 7px 0;

  @include onTablet {
    height: 86px;
    margin: 11px 0;
  }

  @include onDesktop {
    height: 110px;
    margin: 20px 0;
  }
}
