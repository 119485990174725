@import '../../variables.scss';
@import '../../mixins.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
  &__item {
    color: $primary-blue;
    text-decoration-line: none;
    font-weight: 500;

    &:hover {
      color: $dark-blue;
    }
  }

  & img {
    height: 50px;
    width: 50px;
  }
}