@import '../../variables.scss';
@import '../../mixins.scss';

.closed {
  max-width: 390px;

  @include onTablet {
    width: calc(33.333% - 20px);

    display: flex;
    flex-direction: column;
  }

  & > img {
    max-width: 100%;
    margin-bottom: 10px;
  }

  & > h4 {
    color: black;
    font-weight: 700;
  }

  & > p:first-of-type {
    font-size: 12px;
    color: $dark-grey;
    margin-bottom: 0;
    text-align: end;
  }

  & > p:last-of-type {
    font-size: 14px;
    color: $dark-grey;
    background: -webkit-linear-gradient( #000000, #eee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & > button {
    outline: none;
    background-color: transparent;
    color: $primary-blue;
    border: none;
    padding-bottom: 10px;
    text-align: start;

    &:hover {
      text-shadow: $primary-blue 1px 0 10px;
    }
  }
}

.opened {
  margin: 60px 0;
  & > div {
    display: flex;
    margin: 10px;
    justify-content: space-between;

    & > p {
      font-size: 12px;
      color: $dark-grey;
      margin-bottom: 0;
      text-align: end;
    }

    & > button {
      outline: none;
      background-color: transparent;
      color: $primary-blue;
      border: none;
      padding-bottom: 10px;
      text-align: start;
    }
  }

  & > img {
    display: block;
    margin: 0 auto;
    max-height: 400px;
    margin-bottom: 10px;
  }

  & > h4 {
    color: black;
    font-weight: 700;
  }
}
